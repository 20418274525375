define("discourse/plugins/discourse-nationalflags/wizard/components/wizard-field-national-flag", ["exports", "ember-addons/ember-computed-decorators", "wizard/lib/ajax"], function (_exports, _emberComputedDecorators, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = Ember.Component.extend((_dec = (0, _emberComputedDecorators.on)('init'), _dec2 = (0, _emberComputedDecorators.default)('field.value'), (_obj = {
    layoutName: 'javascripts/wizard/templates/components/wizard-field-national-flag',
    natflaglist: [],
    setup() {
      (0, _ajax.ajax)({
        url: '/natflags/flags',
        type: 'GET'
      }).then(natflags => {
        let localised_flags = [];
        localised_flags = natflags.flags.map(element => {
          return {
            code: element.code,
            pic: element.pic,
            description: I18n.t(`flags.description.${element.code}`)
          };
        }).sortBy('description');
        this.set('natflaglist', localised_flags);
      });
    },
    flagsource() {
      return this.get('field.value') == null ? 'none' : this.get('field.value');
    }
  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "flagsource", [_dec2], Object.getOwnPropertyDescriptor(_obj, "flagsource"), _obj)), _obj)));
});